import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import store from "./redux/store";
import './index.css';
import 'bulma/css/bulma.min.css';
import {Provider } from "react-redux";
import MainComponent from './Components/ClientComponenet/MainComponent';
import OutputsComponent from './Components/OutputsComponent/OutputsComponent'
import UsersComponent from './Components/UsersComponent/UsersComponent'

import './index.scss'

const TabComponenet = ({tabname,icon,index,currtab,setCurrTab}) =>
{
  
  return <li className={currtab === index ? "is-active" : ""}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a className='blacktab has-text-white' onClick={() => setCurrTab(index)}>
              <span className="icon is-small "><i className={icon}/></span><span>{tabname}</span>
            </a>
          </li>
}
const MainPage = () =>
{
    const [currtab,setCurrTab] = useState(0);

    return  (
    <>
      <div className='backgroundDiv'></div>
      <div className='MainComponent'>
          <div style={{"position" : "absolute","top" : 0,"right" : 0}}><span className='tag is-info is-medium'>V0.1010</span></div>
          <div className='box has-background-grey-darker fullHeight' style={{"height" : "90%","overflowY" : "scroll"}}>
            <div className="tabs is-boxed is-centered is-fullwidth">
              <ul>
                <TabComponenet tabname={"Clients"} index={0} currtab={currtab} setCurrTab={setCurrTab} icon={"fa-solid fa-outdent"}/>
                <TabComponenet tabname={"Output Tabs"} index={1} currtab={currtab} setCurrTab={setCurrTab} icon={"fa-solid fa-user"}/>
                <TabComponenet tabname={"Users"} index={2} currtab={currtab} setCurrTab={setCurrTab} icon={"fa-solid fa-outdent"}/>
              </ul>
            </div>
            <UsersComponent currtab={currtab} index={2}/>
            <MainComponent currtab={currtab} index={0}/>
            <OutputsComponent currtab={currtab} index={1}/>
          </div>
      </div>
    </>
   );
}

ReactDOM.render(
    <Provider store={store}>
        <MainPage />
    </Provider>,
  document.getElementById('root')
);
