import React from 'react';
import {ChangeClientDictShow, changeModal, deleteIndexCatalog, setClientDictSignALL} from "../../redux/client";
import { useDispatch, useSelector} from "react-redux";


const CheckBoxLabel = ({index}) =>
{
    const { clientDictShow } = useSelector((state) => state.client);
    const dispatch = useDispatch();

    const setOwnedCatalog = async (e) =>
    {
        let catalogStatus = {...clientDictShow["catalogStatus"]};
        catalogStatus[clientDictShow["Catalogs"][index]] = e.currentTarget.checked ? "owned" : "";
        dispatch(ChangeClientDictShow(["catalogStatus",{...catalogStatus}]));
        dispatch(setClientDictSignALL({"clientName":clientDictShow["Name"],"Email" : clientDictShow["Email"],"Catalogs" : clientDictShow["Catalogs"]}));
        dispatch(changeModal("Done,Update"))
    }

    return (
        <>
            <div className='checkboxdiv'>
                <input type="checkbox" onChange={(e) => setOwnedCatalog(e)} checked={clientDictShow["catalogStatus"] !== undefined ? clientDictShow["catalogStatus"][clientDictShow["Catalogs"][index]] === "owned" : false}></input>
            </div>
        </>
    )
}

const CatalogToTags = (props) =>
{
    const dispatch = useDispatch();

    if(props.Catalogs !== undefined)
    {
        let member = [];
        let checkButtons = [];

        for(let
             i = 0;i<props.Catalogs.length;i++)
        {
            if(props.isSign)
                member.push(<React.Fragment key={i}>
                    <span className="tag is-rounded is-black has-text-danger-light">
                        <button className="delete is-small" onClick={(e) =>  {dispatch(deleteIndexCatalog(e.target.parentElement.innerText));}}/>
                        {props.Catalogs[i]}
                    </span></React.Fragment>);
            else
            {
                member.push(<React.Fragment key={i}><span className="tag is-rounded is-black has-text-danger-light">{props.Catalogs[i]}</span></React.Fragment>);
                checkButtons.push(<React.Fragment key={i}><CheckBoxLabel index={i}/></React.Fragment>);

            }
                

        }
        return (<div  className="custom-scrollbar mb-2"  style={{"overflowY":"scroll","overflowX":"hidden","height" : "60%"}}>
                    {
                        props.isSign ?
                            member
                        :
                            <div className='columns is-centered is-vcentered'>
                                <div className='column is-flex is-flex-direction-column	'>
                                    {checkButtons}
                                </div>
                                <div className='column'>
                                    {member}
                                </div>

                            </div>

                    }
                    
                </div>);
    }
    return <></>;
}
export default CatalogToTags;